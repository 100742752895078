export const isAgentParticipant = (participant: IParticipant): participant is IAgentParticipant => {
  const agentTypes = ['AGENT', 'CONVEYANCER', 'BROKER'];
  return agentTypes.includes(participant.id || '') || agentTypes.includes(participant.type);
};

export const isPersonParticipant = (
  participant: IParticipant
): participant is IPersonParticipant => {
  return participant.id === 'PERSON' || participant.type === 'PERSON';
};
