import { convertINameToString } from '@utils/convertINameToString';

export const createRoomName = (
  agentParticipants: IAgentParticipant[],
  personParticipants: IPersonParticipant[]
) => {
  const numberOfParticipants = agentParticipants.length + personParticipants.length;

  if (numberOfParticipants === 0) return '';

  const renderNames = (names: string[]): string => {
    return names.join(', ').replace(/, ([^,]*)$/, ' & $1');
  };

  if (numberOfParticipants === 2) {
    const agentNames = agentParticipants.map(agent => convertINameToString(agent.agency.agent));
    const personNames = personParticipants.map(person => convertINameToString(person.person.name));

    return renderNames([...agentNames, ...personNames]);
  }

  if (numberOfParticipants > 2) {
    const agentNames = agentParticipants.map(agent => agent.agency.agent.firstName);
    const personNames = personParticipants.map(person => person.person.name.firstName);

    return renderNames([...agentNames, ...personNames]);
  }

  return agentParticipants.length > 0
    ? convertINameToString(agentParticipants[0].agency.agent)
    : convertINameToString(personParticipants[0].person.name);
};
