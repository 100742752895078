import { selectMessageRooms } from '@features/messageRooms/messageRooms';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type RoomViews = {
  [roomId: string]: number;
};

const eventEmitter = new EventTarget();

const ROOM_VIEW_UPDATED = 'roomViewUpdated';

/**
 * saveMessageRoomViewingTime
 * @param roomId
 * Will save the current timestamp to local storage for the given room id
 */
export const saveMessageRoomViewingTime = (roomId: string): void => {
  const viewsString = localStorage.getItem('roomViews');
  const views: RoomViews = viewsString ? JSON.parse(viewsString) : {};

  views[roomId] = Date.now();

  localStorage.setItem('roomViews', JSON.stringify(views));

  eventEmitter.dispatchEvent(new Event(ROOM_VIEW_UPDATED));
};

/**
 * useCheckForNewMessagesState
 * @param callback
 * @returns a boolean indicating whether any room has been updated since the last time it was viewed
 * Will add an event listener to check for updates to the room views
 */
export const useCheckForNewMessagesState = (): boolean => {
  const [newMessages, setNewMessages] = useState(false);
  const rooms = useSelector(selectMessageRooms);

  useEffect(() => {
    setNewMessages(checkIfAnyMessageRoomHasNewMessages(rooms));

    const handler = () => {
      setNewMessages(checkIfAnyMessageRoomHasNewMessages(rooms));
    };

    eventEmitter.addEventListener(ROOM_VIEW_UPDATED, handler);

    return () => {
      eventEmitter.removeEventListener(ROOM_VIEW_UPDATED, handler);
    };
  }, [rooms]);

  return newMessages;
};

/**
 * checkIfAnyMessageRoomHasNewMessages
 * @param rooms
 * @returns a boolean indicating whether any room has been updated since the last time it was viewed
 */
export const checkIfAnyMessageRoomHasNewMessages = (rooms: MessageRoom[]): boolean => {
  const data = localStorage.getItem('roomViews');
  const roomViews = !!data ? JSON.parse(data) : {};

  return rooms.some(room => {
    // If there is no last mesage recieved in the room then it can never show as updated
    if (!room.lastMessageTime) {
      return false;
    }

    const roomViewTimestamp = roomViews[room.id];

    // If there is no room view timestamp then user has never checked this room on this device. If there is a last message then we can assume it has been updated since the user has never viewed it.
    if (!roomViewTimestamp && !!room.lastMessageTime) {
      return true;
    }

    return new Date(room.lastMessageTime).getTime() > roomViewTimestamp;
  });
};

/**
 * checkIfMessageRoomHasNewMessages
 * @param rooms
 * @returns a boolean indicating whether a room has been updated since the last time it was viewed
 */
export const checkIfMessageRoomHasNewMessages = (room: MessageRoom): boolean => {
  // If there is no last mesage recieved in the room then it can never show as updated
  if (!room.lastMessageTime) {
    return false;
  }

  const data = localStorage.getItem('roomViews');
  const roomViews = !!data ? JSON.parse(data) : {};

  const roomViewTimestamp = roomViews[room.id];

  // If there is no room view timestamp then user has never checked this room on this device. If there is a last message then we have to show updated as the user has never viewed it.
  if (!roomViewTimestamp && !!room.lastMessageTime) {
    return true;
  }

  return new Date(room.lastMessageTime).getTime() > roomViewTimestamp;
};
