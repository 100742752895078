import axios from 'axios';
import { ApiRoutes } from '@constants/index';

export const checkAuthentication = async (): Promise<boolean> => {
  try {
    const response = await axios.get(ApiRoutes.AUTH_CHECK);

    return response.data?.authenticated || false;
  } catch (error) {
    return false;
  }
};
