import FoxtonsCard from '@images/agencies/foxtons/foxtons_card.webp';
import FoxtonsPill from '@images/agencies/foxtons/foxtons_pill.webp';
import FoxtonsCircleSmall from '@images/agencies/foxtons/foxtons_circle_small.webp';
import FoxtonsCircleLarge from '@images/agencies/foxtons/foxtons_circle_large.webp';

import AlexanderHallCard from '@images/agencies/alexander_hall/alexander_hall_card.webp';
import AlexanderHallPill from '@images/agencies/alexander_hall/alexander_hall_pill.webp';
import AlexanderHallCircleSmall from '@images/agencies/alexander_hall/alexander_hall_circle_small.webp';
import AlexanderHallCircleLarge from '@images/agencies/alexander_hall/alexander_hall_circle_large.webp';

import CdsMayfairCard from '@images/agencies/cds_mayfair/cds_mayfair_card.webp';
import CdsMayfairPill from '@images/agencies/cds_mayfair/cds_mayfair_pill.webp';
import CdsMayfairCircleSmall from '@images/agencies/cds_mayfair/cds_mayfair_circle_small.webp';
import CdsMayfairCircleLarge from '@images/agencies/cds_mayfair/cds_mayfair_circle_large.webp';

import HealysCard from '@images/agencies/healys/healys_card.webp';
import HealysPill from '@images/agencies/healys/healys_pill.webp';
import HealysCircleSmall from '@images/agencies/healys/healys_circle_small.webp';
import HealysCircleLarge from '@images/agencies/healys/healys_circle_large.webp';

import LeadenhallCard from '@images/agencies/leadenhall/leadenhall_card.webp';
import LeadenhallPill from '@images/agencies/leadenhall/leadenhall_pill.webp';
import LeadenhallCircleSmall from '@images/agencies/leadenhall/leadenhall_circle_small.webp';
import LeadenhallCircleLarge from '@images/agencies/leadenhall/leadenhall_circle_large.webp';

import DefaultCard from '@images/agencies/default/default_card.webp';
import DefaultPill from '@images/agencies/default/default_pill.webp';
import DefaultCircleSmall from '@images/agencies/default/default_circle_small.webp';
import DefaultCircleLarge from '@images/agencies/default/default_circle_large.webp';

import CroftCard from '@images/agencies/croft/croft_card.webp';
import CroftPill from '@images/agencies/croft/croft_pill.webp';
import CroftCircleSmall from '@images/agencies/croft/croft_circle_small.webp';
import CroftCircleLarge from '@images/agencies/croft/croft_circle_large.webp';

import HomeCard from '@images/agencies/home/home_card.webp';

export enum Agencies {
  DEFAULT = 'DEFAULT',
  FOXTONS = 'FOXTONS',
  ALEXANDER_HALL = 'ALEXANDER_HALL',
  CDS_MAYFAIR = 'CDS_MAYFAIR',
  HEALYS = 'HEALYS',
  LEADENHALL_LAW_GROUP = 'LEADENHALL_LAW_GROUP',
  HOME = 'HOME',
  CROFT = 'CROFT',
}

export enum AgencyColours {
  DEFAULT = '#111111',
  FOXTONS = '#006B65',
  ALEXANDER_HALL = '#3A9BBB',
  CDS_MAYFAIR = '#478BB5',
  HEALYS = '#121212',
  LEADENHALL_LAW_GROUP = '#042D52',
  HOME = '#EB3754',
  CROFT = '#0E1139',
}

type AgencyDisplay = {
  id: Agencies;
  colour: string;
  images: {
    card: string;
    pill: string;
    circleSmall: string;
    circleLarge: string;
  };
};

export const getAgencyStyling = (agencyId: Agencies): AgencyDisplay => {
  switch (agencyId) {
    case Agencies.FOXTONS:
      return {
        id: Agencies.FOXTONS,
        colour: AgencyColours.FOXTONS,
        images: {
          card: FoxtonsCard,
          pill: FoxtonsPill,
          circleSmall: FoxtonsCircleSmall,
          circleLarge: FoxtonsCircleLarge,
        },
      };
    case Agencies.ALEXANDER_HALL:
      return {
        id: Agencies.ALEXANDER_HALL,
        colour: AgencyColours.ALEXANDER_HALL,
        images: {
          card: AlexanderHallCard,
          pill: AlexanderHallPill,
          circleSmall: AlexanderHallCircleSmall,
          circleLarge: AlexanderHallCircleLarge,
        },
      };
    case Agencies.CDS_MAYFAIR:
      return {
        id: Agencies.CDS_MAYFAIR,
        colour: AgencyColours.CDS_MAYFAIR,
        images: {
          card: CdsMayfairCard,
          pill: CdsMayfairPill,
          circleSmall: CdsMayfairCircleSmall,
          circleLarge: CdsMayfairCircleLarge,
        },
      };
    case Agencies.HEALYS:
      return {
        id: Agencies.HEALYS,
        colour: AgencyColours.HEALYS,
        images: {
          card: HealysCard,
          pill: HealysPill,
          circleSmall: HealysCircleSmall,
          circleLarge: HealysCircleLarge,
        },
      };
    case Agencies.LEADENHALL_LAW_GROUP:
      return {
        id: Agencies.LEADENHALL_LAW_GROUP,
        colour: AgencyColours.LEADENHALL_LAW_GROUP,
        images: {
          card: LeadenhallCard,
          pill: LeadenhallPill,
          circleSmall: LeadenhallCircleSmall,
          circleLarge: LeadenhallCircleLarge,
        },
      };
    case Agencies.HOME:
      return {
        id: Agencies.HOME,
        colour: AgencyColours.HOME,
        images: {
          card: HomeCard,
          pill: DefaultPill,
          circleSmall: DefaultCircleSmall,
          circleLarge: DefaultCircleLarge,
        },
      };
    case Agencies.CROFT:
      return {
        id: Agencies.CROFT,
        colour: AgencyColours.CROFT,
        images: {
          card: CroftCard,
          pill: CroftPill,
          circleSmall: CroftCircleSmall,
          circleLarge: CroftCircleLarge,
        },
      };
    default:
      return {
        id: Agencies.DEFAULT,
        colour: AgencyColours.DEFAULT,
        images: {
          card: DefaultCard,
          pill: DefaultPill,
          circleSmall: DefaultCircleSmall,
          circleLarge: DefaultCircleLarge,
        },
      };
  }
};
