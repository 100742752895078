import fileImg from '@images/icons/file.svg';
import docImg from '@images/icons/doc.svg';
import pdfImg from '@images/icons/pdf.svg';
import pngImg from '@images/icons/png.svg';
import jpgImg from '@images/icons/jpg.svg';

export const getFileImage = (type: string): string => {
  switch (type) {
    case 'image/jpg':
      return jpgImg;
    case 'image/jpeg':
      return jpgImg;
    case 'image/png':
      return pngImg;
    case 'application/msword':
      return docImg;
    case 'application/officedocument.wordprocessingml.document':
      return docImg;
    case 'application/pdf':
      return pdfImg;
    default:
      return fileImg;
  }
};
