/** convertPafToSingleLine
 *
 * This function takes an object shaped like a PAF and converts it to a single line string.
 * For more information on PAF: https://www.royalmail.com/sites/default/files/PAF-Code-of-Practice-211118.pdf
 */
export const convertPafToSingleLine = (paf: IPaf): string => {
  const addressParts = [
    paf.buildingName,
    paf.buildingNumber && paf.street ? `${paf.buildingNumber} ${paf.street}` : paf.street,
    paf.postTown,
    paf.county,
    paf.postcode,
  ];

  return addressParts
    .filter(addressLine => addressLine && addressLine.trim().length > 0)
    .join(', ');
};
